.giswnauce__agenda {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: var(--color-white);
}

.giswnauce__agenda-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.agenda-container_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.agenda-container_header p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 2.5rem;
    color: var(--color-navy);
}

.agenda-container_header h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.agenda-container_subheader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.anchor-agenda {
    display: block;
    position: absolute;
    top: -4rem;
    left: 0;
    visibility: hidden;
}

.agenda-container_subheader h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-black);
}

.agenda-container_description {
    width: 100%;
}

.agenda-container_description table {
    width: 100%;
    border-collapse: collapse;
    font-family: var(--font-family);
    font-size: 1.25rem;
    border: 0.25rem solid var(--color-turquoise);
    margin-bottom: 1.5rem;
}

thead tr {
    background-color: #64ccc5;
    color: #003057;
    font-size: 1.5rem;
    border: 0.25rem solid var(--color-turquoise);
}

thead th {
    padding: 0.5rem 1rem;
}

thead th:last-child {
    white-space: nowrap;
}

tbody td {
    padding: 0.75rem 1rem;
    font-weight: 500;
}

tbody td:first-child {
    text-align: center;
    background-color: rgba(100,204,197,0.5);
    white-space: nowrap;
    max-width: 8rem;
    width: 4.75rem;
}

tbody td:last-child {
    text-align: center;
    background-color: rgba(100,204,197,0.5);
    white-space: nowrap;
    max-width: 8rem;
    width: 4.75rem;
}

tbody tr {
    border-top: 1px solid #bbb;
}

tbody tr:nth-child(8) {
    background-color: #003057;
    color: var(--color-white);
    border: 0.25rem solid var(--color-turquoise);
}

tbody th {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    border-top: 0.25rem solid var(--color-navy);
    border-right: 0.25rem solid var(--color-navy);
    border-bottom: 0.25rem solid var(--color-navy);
    border-left: 0.25rem solid var(--color-navy);
}

.first_table tbody tr:last-child {
    border-bottom: 0.25rem solid var(--color-navy);
    border-left: 0.25rem solid var(--color-navy);
    border-right: 0.25rem solid var(--color-navy);
}

.first_table tbody tr:last-child td:first-child {
    color: var(--color-white);
    background-color: rgba(0,48,87,0.85);
}

.first_table tbody tr:last-child td:last-child {
    color: var(--color-white);
    background-color: rgba(0,48,87,0.85);
}

.first_table tbody tr:nth-last-child(n + 3):hover {
    background-color: rgba(100,204,197,0.3);
}

.first_table tbody tr:nth-last-child(1):hover {
    background-color: rgba(0,48,87,0.2);
}

.second_table tbody tr:nth-child(n + 1):hover {
    background-color: rgba(100,204,197,0.3);
}

@media screen and (max-width: 1200px) {
    .agenda-container_header p {
        font-size: 2.2rem;
    }
    
    .agenda-container_header h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    
    .agenda-container_subheader h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .agenda-container_description table {
        font-size: 1.15rem;
    }

    thead tr {
        font-size: 1.4rem;
    }

    tbody th {
        font-size: 1.4rem;
    }
}
    
@media screen and (max-width: 1024px) {
    .anchor-scientific-agenda {
        top: -4rem;
    }
    .giswnauce__agenda {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    .agenda-container_header p {
        font-size: 2rem;
    }
    
    .agenda-container_header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
    .agenda-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .agenda-container_description table {
        font-size: 1.1rem;
    }

    thead tr {
        font-size: 1.35rem;
    }

    tbody th {
        font-size: 1.35rem;
        padding: 0.5rem 0.75rem;
    }

    thead th {
        padding: 0.5rem 0.75rem;
    }

    tbody td {
        padding: 0.5rem 0.5rem;
    }
}
    
@media screen and (max-width: 900px) {
    .anchor-agenda {
        top: -4rem;
    }
    .agenda-container_header {
        margin-bottom: 1rem;   
    }
    .agenda-container_subheader {
        margin-bottom: 1rem;
    }
    .agenda-container_header p {
        font-size: 1.6rem;
        margin-right: 0.75rem;
    }
    
    .agenda-container_header h1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    
    .agenda-container_subheader h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .agenda-container_description table {
        font-size: 1rem;
    }

    thead th {
        padding: 0.25rem 0.5rem;
    }

    thead tr {
        font-size: 1.25rem;
    }

    tbody th {
        font-size: 1.25rem;
        padding: 0.25rem 0.5rem;
    }
}
    
@media screen and (max-width: 768px) {
    .anchor-agenda {
        top: -3.5rem;
    }
    .giswnauce__agenda {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .agenda-container_header {
        margin-bottom: 0.75rem;   
    }
    .agenda-container_subheader {
        margin-bottom: 0.75rem;
    }
    .agenda-container_header p {
        font-size: 1.4rem;
    }
    
    .agenda-container_header h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .agenda-container_subheader h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    
    .agenda-container_description table {
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
    }

    thead tr {
        font-size: 1.1rem;
    }

    tbody th {
        font-size: 1.1rem;
    }
}
    
@media screen and (max-width: 600px) {
    .anchor-scientific-agenda {
        top: -3rem;
    }
    .giswnauce__agenda {
        background-color: transparent;
    }
    .agenda-container_header {
        margin-bottom: 0.5rem;   
    }
    .agenda-container_subheader {
        margin-bottom: 0.5rem;
    }
    .agenda-container_header p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }
    
    .agenda-container_header h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .agenda-container_subheader h2 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    
    .agenda-container_description table {
        font-size: 0.8rem;
        
    }

    thead tr {
        font-size: 1rem;
    }

    tbody th {
        font-size: 1rem;
    }
}
