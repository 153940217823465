.giswnauce__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 4rem;
    width: 100%;
    background-color: var(--color-turquoise);
    z-index: 1;
    border-top: 0.25rem solid var(--color-navy);
}

.giswnauce__footer p {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-navy);
    line-height: 1.5rem;
    font-size: 1.25rem;
}

@media screen and (max-width: 1200px) {
    .giswnauce__footer p {
        font-size: 1.15rem;
    }
}
        
@media screen and (max-width: 1024px) {
    .giswnauce__footer p {
        font-size: 1.1rem;
    }
}
        
@media screen and (max-width: 900px) {
    .giswnauce__footer p {
        font-size: 1rem;
    }
}
        
@media screen and (max-width: 768px) {
    .giswnauce__footer p {
        font-size: 0.9rem;
    }
}
        
@media screen and (max-width: 600px) {
    .giswnauce__footer p {
        font-size: 0.8rem;
    }
}