.giswnauce__committees {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: var(--color-white);
}

.giswnauce__committees-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.committees-container_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.committees-container_header p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 2.5rem;
    color: var(--color-navy);
}

.committees-container_header h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.committees-container_subheader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.anchor-scientific-committee {
    display: block;
    position: absolute;
    top: -9rem;
    left: 0;
    visibility: hidden;
}

.anchor-organizing-committee {
    display: block;
    position: absolute;
    top: -4rem;
    left: 0;
    visibility: hidden;
}

.committees-container_subheader h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-black);
}

.committees-container_description li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--color-black);
    margin-bottom: 1rem;
    list-style-position: none;
}

.committees-container_description img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border: 0.25rem solid var(--color-turquoise);
    border-radius: 1rem;
}

.committees-container_description li:last-of-type {
    margin-bottom: 1.5rem;
}

.committees-container_description h3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin-bottom: 0.25rem;
    font-weight: 700;
    color: var(--color-turquoise);
}

.committees-container_description a {
    width: fit-content;
    height: fit-content;
}

.committees-container_description a:hover {
    text-decoration: underline;
    opacity: 75%;
    transition: 0.25s;
}

.committee-more {
    margin-right: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .committees-container_header p {
        font-size: 2.2rem;
    }
    
    .committees-container_header h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    
    .committees-container_subheader h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .committees-container_description li {
        font-size: 1.15rem;
    }
    
    .committees-container_description img {
        width: 4.5rem;
        height: 4.5rem;
    }
    
    .committees-container_description h3 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}
    
@media screen and (max-width: 1024px) {
    .anchor-scientific-committee {
        top: -8rem;
    }
    .giswnauce__committees {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    .committees-container_header p {
        font-size: 2rem;
    }
    
    .committees-container_header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
    .committees-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .committees-container_description li {
        font-size: 1.1rem;
    }
    
    .committees-container_description img {
        width: 4.3rem;
        height: 4.3rem;
    }
    
    .committees-container_description h3 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
}
    
@media screen and (max-width: 900px) {
    .anchor-scientific-committee {
        top: -7rem;
    }
    .committees-container_header {
        margin-bottom: 1rem;   
    }
    .committees-container_subheader {
        margin-bottom: 1rem;
    }
    .committees-container_header p {
        font-size: 1.6rem;
        margin-right: 0.75rem;
    }
    
    .committees-container_header h1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    
    .committees-container_subheader h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .committees-container_description li {
        font-size: 1rem;
    }
    
    .committees-container_description img {
        width: 4rem;
        height: 4rem;
    }
    
    .committees-container_description h3 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }

    .committee-more {
        margin-right: 1rem;
    }
}
    
@media screen and (max-width: 768px) {
    .anchor-scientific-committee {
        top: -5.5rem;
    }
    .anchor-organizing-committee {
        top: -3.5rem;
    }
    .giswnauce__committees {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .committees-container_header {
        margin-bottom: 0.75rem;   
    }
    .committees-container_subheader {
        margin-bottom: 0.75rem;
    }
    .committees-container_header p {
        font-size: 1.4rem;
    }
    
    .committees-container_header h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .committees-container_subheader h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    
    .committees-container_description li {
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
    }
    
    .committees-container_description img {
        width: 3.5rem;
        height: 3.5rem;
    }
    
    .committees-container_description h3 {
        font-size: 0.95rem;
        line-height: 0.95rem;
        margin-bottom: 0.1rem
    }
}
    
@media screen and (max-width: 600px) {
    .anchor-scientific-committee {
        top: -5rem;
    }
    .anchor-organizing-committee {
        top: -3rem;
    }
    .giswnauce__committees {
        background-color: transparent;
    }
    .committees-container_header {
        margin-bottom: 0.5rem;   
    }
    .committees-container_subheader {
        margin-bottom: 0.5rem;
    }
    .committees-container_header p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }
    
    .committees-container_header h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .committees-container_subheader h2 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    
    .committees-container_description li {
        font-size: 0.8rem;
    }
    
    .committees-container_description img {
        width: 3.3rem;
        height: 3.3rem;
    }
    
    .committees-container_description h3 {
        font-size: 0.85rem;
        line-height: 0.85rem;
    }
}