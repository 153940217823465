.giswnauce__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    border-left: 0.25rem solid var(--color-turquoise);
    border-bottom: 0.25rem solid var(--color-turquoise);
    background-color: var(--color-white);
}

.giswnauce__right-event_organizer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1rem;
}

.event_organizer-header {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-turquoise);
    border: 0;
    margin-bottom: 1rem;
}

.event_organizer-header-GUS {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-turquoise);
    border: 0;
    margin-bottom: 1rem;
}

.event_organizer-header h1, .event_organizer-header-GUS h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: var(--color-navy);
}

.event_organizer-items {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.organizer-items-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: 0.25s;
}

.organizer-items-logo-esri {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 8rem;
    height: 100%;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    transition: 0.25s;
}

.organizer-items-logo-gus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 8rem;
    height: 100%;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    transition: 0.25s;
}

.event_organizer-items img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.organizer-items-logo:hover {
    opacity: 75%;
    transition: 0.25s;
}

.organizer-items-logo-esri:hover {
    opacity: 75%;
    transition: 0.25s;
}

.organizer-items-logo-gus:hover {
    opacity: 75%;
    transition: 0.25s;
}

@media screen and (max-width: 1200px) {
    .event_organizer-header {
        height: 2.2rem;
    }
    .event_organizer-header-GUS {
        height: 3.2rem;
    }
    .event_organizer-header h1, .event_organizer-header-GUS h1 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
    .organizer-items-logo-esri {
        min-height: 7rem;
    }
    .organizer-items-logo-gus {
        min-height: 7rem;
    }
}

@media screen and (max-width: 1024px) {
    .giswnauce__right {
        margin-top: 2rem;
    }
    .event_organizer-header {
        height: 2rem;
    }
    .event_organizer-header-GUS {
        height: 3rem;
    }
    .event_organizer-header h1, .event_organizer-header-GUS h1 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .organizer-items-logo-esri {
        min-height: 6rem;
    }
    .organizer-items-logo-gus {
        min-height: 6rem;
    }
}
    
@media screen and (max-width: 900px) {
    .event_organizer-header {
        margin-bottom: 0.75rem;
    }
    .event_organizer-header-GUS {
        height: 2.5rem;
        margin-bottom: 0.75rem;
    }
    .giswnauce__right-event_organizer {
        margin-bottom: 0.75rem;
    }
    .organizer-items-logo {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
    }
    .event_organizer-header {
        height: 1.6rem;
    }
    .event_organizer-header h1, .event_organizer-header-GUS h1 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    .organizer-items-logo-esri {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .organizer-items-logo-gus {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
    
@media screen and (max-width: 768px) {
    .event_organizer-header {
        margin-bottom: 0.5rem
    }
    .event_organizer-header-GUS {
        height: 2.25rem;
        margin-bottom: 0.5rem;
    }
    .giswnauce__right-event_organizer {
        margin-bottom: 0.5rem;
    }
    .organizer-items-logo {
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .giswnauce__right {
        margin-top: 1.5rem;
    }
    .event_organizer-header {
        height: 1.4rem;
    }
    .event_organizer-header h1, .event_organizer-header-GUS h1 {
        font-size: 0.95rem;
        line-height: 0.95rem;
    }
    .organizer-items-logo-esri {
        min-height: 5rem;
    }
    .organizer-items-logo-gus {
        min-height: 5rem;
    }
}
    
@media screen and (max-width: 600px) {
    .event_organizer-header {
        height: 1.25rem;
    }
    .event_organizer-header-GUS {
        height: 1.8rem;
    }
    .event_organizer-header h1, .event_organizer-header-GUS h1 {
        font-size: 0.7rem;
        line-height: 0.7rem;
    }
    .organizer-items-logo-esri {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        min-height: 4.5rem;
    }
    .organizer-items-logo-gus {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        min-height: 4.5rem;
    }
}

@media screen and (max-width: 480px) {
    .organizer-items-logo-esri {
        min-height: 3rem;
    }
    .organizer-items-logo-gus {
        min-height: 3rem;
    }
}