.scroll-margin {
    margin-top: 3rem;
}

.giswnauce__abstracts {
    position: relative;
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: var(--color-white);
    margin-bottom: 2rem;
}

.anchor-posters {
    display: block;
    position: absolute;
    top: -4rem;
    left: 0;
    visibility: hidden;
}

.abstracts-container_header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.abstracts-container_subheader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.5rem;
}

.abstracts-container_header h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.abstracts-container_subheader h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-black);
}

.abstracts-container_subheader h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: red;
    margin-top: 0.5rem;
}

.abstracts-container_subheader h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: var(--color-black);
    margin-top: 0.5rem;
}

.abstracts-container_subheader span:first-child {
    margin-right: 0.5rem;
}

.abstracts-container_subheader span:last-child {
    margin-left: 0.5rem;
}

.giswnauce__abstracts-content-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;
}

.content_element-1 {
    font-family: var(--font-family);
    width: 100%;
    padding: 1rem 1rem;
    border: 0.25rem solid var(--color-navy);
    border-radius: 0.5rem;
    transition: 0.25s;
    opacity: 1;
}

.content_element-1 h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.content_element-1 h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--color-navy);
    margin-bottom: 0.75rem;
}

.content_element-1 p {
    font-size: 1.25rem;
    font-weight: 500;
}

.live-content-two-rows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;
}

.content-two-rows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;
}

.content-one-row {
    display: flex;
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;
}

.two-rows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
}

.live-two-rows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
}

.two-rows-container h1 {
    text-align: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-black);
    margin-bottom: 1rem;
}

.live-two-rows-container h1 {
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: red;
    margin-bottom: 1rem;
}

.giswnauce__abstracts-content {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
}

.giswnauce__abstracts-content-poster {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
}

.live-content_element-container {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    width: 100%;
    height: fit-content;
}

.live-content_element-container:empty {
    display: none;
}

.content_element.live {
    position: relative;
    opacity: 1;
    border-color: red;
    transition: opacity 0.25s ease, border-color 0.25s ease;
}

.content_element.live::before {
    content: 'Na żywo';
    position: absolute;
    top: -0.85rem;
    left: 0.5rem;
    background-color: white;
    color: red;
    padding: 0rem 0.5rem;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.25rem;
    border-radius: 3px;
}

.content_element {
    font-family: var(--font-family);
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    border: 0.25rem solid var(--color-navy);
    border-radius: 0.5rem;
    transition: 0.25s;
    opacity: 0.5;
}

.content_element h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.content_element h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--color-navy);
    margin-bottom: 0.75rem;
}

.content_element p {
    font-size: 1.25rem;
    font-weight: 500;
}

.content_element:hover {
    opacity: 1;
    transition: 0.25s;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .abstracts-container_header h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    .abstracts-container_subheader h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .abstracts-container_subheader h3 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .abstracts-container_subheader h4 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .content_element-1 h2 {
        font-size: 1.4rem;
    }
    .content_element-1 h3 {
        font-size: 1.2rem;
    }
    .content_element-1 p {
        font-size: 1.2rem;
    }
    .content_element h2 {
        font-size: 1.4rem;
        font-weight: 700;
    }
    .content_element h3 {
        font-size: 1.2rem;
        font-weight: 700;
    }
    .content_element p {
        font-size: 1.2rem;
        font-weight: 500;
    }
    .content_element.live::before {
        top: -0.85rem;
        left: 0.5rem;
        padding: 0rem 0.5rem;
        font-size: 1.2rem;
    }
    .two-rows-container h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .live-two-rows-container h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
}
        
@media screen and (max-width: 1024px) {
    .giswnauce__abstracts {
        padding: 1.5rem 2rem;
    }
    .abstracts-container_header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    .abstracts-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .abstracts-container_subheader h3 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .abstracts-container_subheader h4 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .content_element-1 h2 {
        font-size: 1.4rem;
    }
    .content_element-1 h3 {
        font-size: 1.1rem;
    }
    .content_element-1 p {
        font-size: 1.1rem;
    }
    .content_element h2 {
        font-size: 1.4rem;
        font-weight: 700;
    }
    .content_element h3 {
        font-size: 1.1rem;
        font-weight: 700;
    }
    .content_element p {
        font-size: 1.1rem;
        font-weight: 500;
    }
    .content_element.live::before {
        top: -0.85rem;
        left: 0.5rem;
        padding: 0rem 0.5rem;
        font-size: 1.1rem;
    }
    .two-rows-container h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .live-two-rows-container h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
}
        
@media screen and (max-width: 900px) {
    .abstracts-container_header {
        margin-bottom: 1rem;
    }
    .abstracts-container_subheader {
        margin-bottom: 1rem;
    }
    .giswnauce__abstracts-content-1 {
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
    }
    .live-content-two-rows {
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
    }
    .content-two-rows {
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
    }
    .content-one-row {
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
    }
    .giswnauce__abstracts-content {
        grid-gap: 1.5rem;
    }
    .giswnauce__abstracts-content-poster {
        grid-gap: 1.5rem;
    }
    .live-content_element-container {
        grid-gap: 1.5rem;
    }
    .abstracts-container_header h1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .abstracts-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .abstracts-container_subheader h3 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .abstracts-container_subheader h4 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .content_element-1 h2 {
        font-size: 1.25rem;
    }
    .content_element-1 h3 {
        font-size: 1rem;
    }
    .content_element-1 p {
        font-size: 1rem;
    }
    .content_element h2 {
        font-size: 1.25rem;
        font-weight: 700;
    }
    .content_element h3 {
        font-size: 1rem;
        font-weight: 700;
    }
    .content_element p {
        font-size: 1rem;
        font-weight: 500;
    }
    .content_element.live::before {
        top: -0.85rem;
        left: 0.5rem;
        padding: 0rem 0.5rem;
        font-size: 1rem;
    }
    .two-rows-container h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .live-two-rows-container h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .anchor-posters {
        top: -3.75rem;
    }
}
        
@media screen and (max-width: 768px) {
    .giswnauce__abstracts {
        padding: 1.5rem 1.5rem;
    }
    .scroll-margin {
        margin-top: 2.5rem;
    }
    .abstracts-container_header h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .abstracts-container_subheader h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .abstracts-container_subheader h3 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .abstracts-container_subheader h4 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .content_element-1 h2 {
        font-size: 1.15rem;
    }
    .content_element-1 h3 {
        font-size: 0.9rem;
    }
    .content_element-1 p {
        font-size: 0.9rem;
    }
    .content_element h2 {
        font-size: 1.15rem;
        font-weight: 700;
    }
    .content_element h3 {
        font-size: 0.9rem;
        font-weight: 700;
    }
    .content_element p {
        font-size: 0.9rem;
        font-weight: 500;
    }
    .content_element.live::before {
        top: -0.65rem;
        padding: 0rem 0.5rem;
        font-size: 0.9rem;
    }
    .two-rows-container h1 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .live-two-rows-container h1 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .anchor-posters {
        top: -3.5rem;
    }
}
        
@media screen and (max-width: 600px) {
    .abstracts-container_header {
        margin-bottom: 0.5rem;
    }
    .abstracts-container_subheader {
        margin-bottom: 0.5rem;
    }
    .giswnauce__abstracts-content-1 {
        grid-gap: 1rem;
        margin-bottom: 0.5rem;
    }
    .live-content-two-rows {
        grid-gap: 1rem;
        margin-bottom: 0.5rem;
    }
    .content-two-rows {
        grid-gap: 1rem;
        margin-bottom: 0.5rem;
    }
    .content-one-row {
        grid-gap: 1rem;
        margin-bottom: 0.5rem;
    }
    .giswnauce__abstracts-content {
        grid-gap: 1rem;
    }
    .giswnauce__abstracts-content-poster {
        grid-gap: 1rem;
    }
    .live-content_element-container {
        grid-gap: 1rem;
    }
    .abstracts-container_header h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .abstracts-container_subheader h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .abstracts-container_subheader h3 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    .abstracts-container_subheader h4 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    .content_element-1 h2 {
        font-size: 1.05rem;
    }
    .content_element-1 h3 {
        font-size: 0.8rem;
    }
    .content_element-1 p {
        font-size: 0.8rem;
    }
    .content_element h2 {
        font-size: 1.05rem;
        font-weight: 700;
    }
    .content_element h3 {
        font-size: 0.8rem;
        font-weight: 700;
    }
    .content_element p {
        font-size: 0.8rem;
        font-weight: 500;
    }
    .content_element.live::before {
        padding: 0rem 0.5rem;
        font-size: 0.8rem;
    }
    .two-rows-container h1 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    .live-two-rows-container h1 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }
    .giswnauce__abstracts {
        background-color: transparent;
    }
    .anchor-posters {
        top: -2.75rem;
    }
}