.giswnauce__participation {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: var(--color-white);
    user-select:text;
}

.giswnauce__participation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.participation-container_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.participation-container_header p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 2.5rem;
    color: var(--color-navy);
}

.participation-container_header h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.participation-container_subheader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.anchor-participation {
    display: block;
    position: absolute;
    top: -4rem;
    left: 0;
    visibility: hidden;
}

.participation-container_subheader h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: red;
}

.participation-container_subheader h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0.5rem 1.5rem;
    border: 0.25rem solid var(--color-navy);
    border-radius: 1rem;
    background-color: var(--color-turquoise);
    color: var(--color-navy);
    transition: 0.25s;
}

.participation-container_subheader a:hover > h3 {
    background-color: var(--color-navy);
    color: var(--color-turquoise);
    transition: 0.25s;
}

.participation-container_description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.participation-container_description p {
font-family: var(--font-family);
font-size: 1.25rem;
line-height: 1.55rem;
font-weight: 700;
color: red;
}

.participation-container_description a {
    color: var(--color-turquoise);
}

.participation-container_description hr {
    width: 100%;
    border-width: 0.15rem;
    border-style: solid;
    border-color: var(--color-turquoise);
    margin-top: 1rem;
    margin-bottom: 0.25rem;
}

.participation-container_description p:last-of-type {
    color: var(--color-turquoise);
}

.participation-container_description h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    font-family: var(--font-family);
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: var(--color-black);
}

.participation-container_description h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--color-black);
}

@media screen and (max-width: 1200px) {
    .participation-container_header p {
        font-size: 2.2rem;
    }
    
    .participation-container_header h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    
    .participation-container_subheader h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .participation-container_subheader h3 {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    .participation-container_description p {
        line-height: 1.35rem;
        font-size: 1.15rem;
    }

    .participation-container_description h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    .participation-container_description h3 {
        font-size: 1.15rem;
    }
}
    
@media screen and (max-width: 1024px) {
    .giswnauce__participation {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    .participation-container_header p {
        font-size: 2rem;
    }
    
    .participation-container_header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
    .participation-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .participation-container_subheader h3 {
        font-size: 1.4rem;
        line-height: 1.65rem;
    }

    .participation-container_description p {
        line-height: 1.3rem;
        font-size: 1.1rem;
    }

    .participation-container_description h2 {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .participation-container_description h3 {
        font-size: 1.1rem;
    }
}
    
@media screen and (max-width: 900px) {
    .anchor-participation {
        top: -4rem;
    }
    .participation-container_header {
        margin-bottom: 1rem;   
    }
    .participation-container_subheader {
        margin-bottom: 1rem;
    }
    .participation-container_header p {
        font-size: 1.6rem;
        margin-right: 0.75rem;
    }
    
    .participation-container_header h1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    
    .participation-container_subheader h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    .participation-container_subheader h3 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .participation-container_description p {
        line-height: 1.2rem;
        font-size: 1rem;
    }

    .participation-container_description h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }

    .participation-container_description h3 {
        font-size: 1rem;
    }
}
    
@media screen and (max-width: 768px) {
    .anchor-participation {
        top: -3.5rem;
    }
    .giswnauce__participation {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .participation-container_header {
        margin-bottom: 0.75rem;   
    }
    .participation-container_subheader {
        margin-bottom: 0.75rem;
    }
    .participation-container_header p {
        font-size: 1.4rem;
    }
    
    .participation-container_header h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .participation-container_subheader h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }

    .participation-container_subheader h3 {
        font-size: 1.15rem;
        line-height: 1.4rem;
    }

    .participation-container_description p {
        line-height: 1.1rem;
        font-size: 0.9rem;
    }

    .participation-container_description h2 {
        font-size: 1.1rem;
        line-height: 1.1rem;
        margin-top: 0.25rem;
    }

    .participation-container_description h3 {
        font-size: 0.9rem;
    }
}
    
@media screen and (max-width: 600px) {
    .giswnauce__participation {
        background-color: transparent;
    }
    .participation-container_header {
        margin-bottom: 0.5rem;   
    }
    .participation-container_subheader {
        margin-bottom: 0.5rem;
    }
    .participation-container_header p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }
    
    .participation-container_header h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .participation-container_subheader h2 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }

    .participation-container_subheader h3 {
        font-size: 1.05rem;
        line-height: 1.25rem;
    }

    .participation-container_description p {
        line-height: 1rem;
        font-size: 0.8rem;
    }

    .participation-container_description h2 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }

    .participation-container_description h3 {
        font-size: 0.8rem;
    }
}
