.giswnauce__contact {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: var(--color-white);
}

.giswnauce__contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.contact-container_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.contact-container_header p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 2.5rem;
    color: var(--color-navy);
}

.contact-container_header h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
}

.contact-container_subheader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
}

.anchor-contact {
    display: block;
    position: absolute;
    top: -4rem;
    left: 0;
    visibility: hidden;
}

.contact-container_subheader h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-black);
}

.contact-container_description {
    width: fit-content;
}

.contact-container_description h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: fit-content;
    font-family: var(--font-family);
    font-weight: 700;
    margin-bottom: 0.5rem;
    width: fit-content;
    font-size: 1.25rem;
    color: var(--color-black);
}

.contact-container_description h3:last-child {
    border: 0.25rem solid var(--color-navy);
    border-radius: 0.75rem;
    background-color: var(--color-turquoise);
    color: var(--color-navy);
    padding: 0rem 1rem;
    line-height: 2rem;
    margin-bottom: 0.75rem;
    transition: 0.25s;
}

.contact-container_description a {
    width: fit-content;
    max-width: fit-content;
}

.contact-container_description a:hover > h3 {
    background-color: var(--color-navy);
    color: var(--color-turquoise);
    transition: 0.25s;
}

.contact-container_description span {
    margin-right: 0.75rem;
}

@media screen and (max-width: 1200px) {
    .contact-container_header p {
        font-size: 2.2rem;
    }
    
    .contact-container_header h1 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    
    .contact-container_subheader h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .contact-container_description h3 {
        font-size: 1.15rem;
    }
}
    
@media screen and (max-width: 1024px) {
    .giswnauce__contact {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    .contact-container_header p {
        font-size: 2rem;
    }
    
    .contact-container_header h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
    .contact-container_subheader h2 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }

    .contact-container_description h3 {
        font-size: 1.1rem;
    }

    .contact-container_description h3:last-child {
        line-height: 1.75rem;
    }
}
    
@media screen and (max-width: 900px) {
    .anchor-contact {
        top: -4rem;
    }
    .contact-container_header {
        margin-bottom: 1rem;   
    }
    .contact-container_subheader {
        margin-bottom: 1rem;
    }
    .contact-container_header p {
        font-size: 1.6rem;
        margin-right: 0.75rem;
    }
    
    .contact-container_header h1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    
    .contact-container_subheader h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    .contact-container_description h3 {
        font-size: 1rem;
    }

    .contact-container_description h3:last-child {
        line-height: 1.5rem;
        padding: 0rem 0.75rem;
    }
}
    
@media screen and (max-width: 768px) {
    .anchor-contact {
        top: -3.5rem;
    }
    .giswnauce__contact {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .contact-container_header {
        margin-bottom: 0.75rem;   
    }
    .contact-container_subheader {
        margin-bottom: 0.75rem;
    }
    .contact-container_header p {
        font-size: 1.4rem;
    }
    
    .contact-container_header h1 {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    
    .contact-container_subheader h2 {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }

    .contact-container_description h3 {
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
    }
}
    
@media screen and (max-width: 600px) {
    .giswnauce__contact {
        background-color: transparent;
    }
    .contact-container_header {
        margin-bottom: 0.5rem;   
    }
    .contact-container_subheader {
        margin-bottom: 0.5rem;
    }
    .contact-container_header p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }
    
    .contact-container_header h1 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    
    .contact-container_subheader h2 {
        font-size: 1.05rem;
        line-height: 1.05rem;
    }

    .contact-container_description h3 {
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }

    .contact-container_description h3:last-child {
        line-height: 1.25rem;
        padding: 0rem 0.5rem;
    }
}
