.scroll-margin {
    margin-top: 3rem;
}

.giswnauce__abstracts-details {
    position: relative;
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    background-color: var(--color-white);
    margin-bottom: 2rem;
}

.abstract-details-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
}

.details_time {
    font-family: var(--font-family);
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: var(--color-navy);
}

.details_title {
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--color-navy);
    margin-bottom: 1rem;
}

.details_people {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
}

.details_person {
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--color-black);
    margin-bottom: 1rem;
}

.details_people sup {
    color: red;
    font-weight: 700;
}

.details_affiliation {
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--color-black);
}

.details_affiliation sup {
    color: red;
    font-weight: 700;
    margin-right: 0.25rem;
    display: inline-block;
}

.details_email {
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 1rem;
}

.details_email sup {
    color: red;
    font-weight: 700;
    margin-right: 0.25rem;
    display: inline-block;
}

.details_abstract {
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 0.5rem;
}

.details_key {
    font-family: var(--font-family);
    font-size: 1.15rem;
    font-weight: 500;
    color: var(--color-turquoise);
    margin-bottom: 0.5rem;
}

.details_funding {
    font-family: var(--font-family);
    font-size: 1rem;
    line-height: 1rem;
    font-weight:500;
    color: var(--color-black);
}

.details_separator {
    width: 100%;
    height: 0.2rem;
    border-radius: 0.5rem;
    background-color: var(--color-navy);
    margin-bottom: 1.5rem;
    margin-top: 2rem;
}

.details_key:last-of-type {
    margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
    .details_time {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .details_title {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    .details_person {
        font-size: 1.2rem;
    }
    .details_affiliation {
        font-size: 1.2rem;
    }
    .details_email {
        font-size: 1.2rem;
    }
    .details_abstract {
        font-size: 1.2rem;
    }
    .details_key {
        font-size: 1.1rem;
    }
    .details_funding {
        font-size: 0.95rem;
    }
}
        
@media screen and (max-width: 1024px) {
    .giswnauce__abstracts-details {
        padding: 2rem 2rem;
    }
    .details_time {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .details_title {
        font-size: 2rem;
        line-height: 2rem;
    }
    .details_person {
        font-size: 1.1rem;
    }
    .details_affiliation {
        font-size: 1.1rem;
    }
    .details_email {
        font-size: 1.1rem;
    }
    .details_abstract {
        font-size: 1.1rem;
    }
    .details_key {
        font-size: 1.05rem;
    }
    .details_funding {
        font-size: 0.9rem;
    }
}
        
@media screen and (max-width: 900px) {
    .details_title {
        margin-bottom: 0.75rem;
    }
    .details_person {
        margin-bottom: 0.75rem;
    }
    .details_email {
        margin-bottom: 0.75rem;
    }
    .details_separator {
        margin-top: 1.5rem;
        margin-bottom: 1.25rem;
    }
    .giswnauce__abstracts-details {
        padding: 2rem 2rem;
    }
    .details_time {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .details_title {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .details_person {
        font-size: 1rem;
    }
    .details_affiliation {
        font-size: 1rem;
    }
    .details_email {
        font-size: 1rem;
    }
    .details_abstract {
        font-size: 1rem;
    }
    .details_key {
        font-size: 0.95rem;
    }
    .details_funding {
        font-size: 0.85rem;
    }
}
        
@media screen and (max-width: 768px) {
    .scroll-margin {
        margin-top: 2.5rem;
    }
    .giswnauce__abstracts-details {
        padding: 1.5rem 1.5rem;
    }
    .details_time {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .details_title {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .details_person {
        font-size: 0.9rem;
    }
    .details_affiliation {
        font-size: 0.9rem;
    }
    .details_email {
        font-size: 0.9rem;
    }
    .details_abstract {
        font-size: 0.9rem;
    }
    .details_key {
        font-size: 0.85rem;
    }
    .details_funding {
        font-size: 0.8rem;
    }
}
        
@media screen and (max-width: 600px) {
    .details_title {
        margin-bottom: 0.5rem;
    }
    .details_person {
        margin-bottom: 0.5rem;
    }
    .details_email {
        margin-bottom: 0.5rem;
    }
    .details_separator {
        margin-top: 1rem;
        margin-bottom: 0.75rem;
    }
    .details_time {
        font-size: 1.15rem;
        line-height: 1.15rem;
    }
    .details_title {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
    .details_person {
        font-size: 0.8rem;
    }
    .details_affiliation {
        font-size: 0.8rem;
    }
    .details_email {
        font-size: 0.8rem;
    }
    .details_abstract {
        font-size: 0.8rem;
    }
    .details_key {
        font-size: 0.75rem;
    }
    .details_funding {
        font-size: 0.75rem;
    }
    .giswnauce__abstracts-details {
        background-color: transparent;
    }
}