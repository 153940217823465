.anchor-home {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.sticky-navbar {
    position: absolute;
    bottom: 0;
    left: 0;
}

.giswnauce__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.giswnauce__header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.giswnauce__header-content img {
    width: 100%;
    vertical-align: bottom;
}