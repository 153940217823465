.giswnauce__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
}

.no-scroll {
  overflow: hidden;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--color-turquoise);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}