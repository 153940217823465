@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: 'Barlow Condensed', sans-serif;

    --gradient-text: linear-gradient(90deg, #FFBF34 0%, #8C6000 25%, #FDBA00 50%, #946F1F 75%, #FFCC00 100%);

    --color-black: #000;
    --color-navy: #003057;
    --color-turquoise: #64ccc5;
    --color-white: #fff;
}