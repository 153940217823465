.giswnauce__navbar {
    position: relative;
    height: 3rem;
    display: flex;
    width: 100%;
    padding: 0 3rem;
    justify-content: center;
    align-items: center;
    background: var(--color-turquoise);
    border-bottom: 0.25rem solid var(--color-navy);
    z-index: 3;
}

.scroll-nav {
    position: fixed;
    top: 0;
    width: 1048px;
    box-sizing: border-box;
}

.scroll-dropdown-fixed {
    position: fixed;
    top: 2.5rem;
}

.scroll-dropdown-absolute {
    position: absolute;
    height: fit-content;
}

.giswnauce__navbar-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 2rem;
    height: 100%;
}

.giswnauce__navbar-links p {
    position: relative;
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-navy);
    transition: 0.25s;
}

.giswnauce__navbar-links a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.giswnauce__navbar-links span {
    position: absolute;
    bottom: 0;
    height: 0rem;
    width: 100%;
    background-color: var(--color-navy);
    display: block;
    margin: 0 auto;
    transition: 0.25s;
}

#about:hover > #line1 {
    height: 0.25rem;
    transition: 0.25s;
}

#committees:hover > #line2 {
    height: 0.25rem;
    transition: 0.25s;
}

#agenda:hover > #line3 {
    height: 0.25rem;
    transition: 0.25s;
}

#participation:hover > #line4 {
    height: 0.25rem;
    transition: 0.25s;
}

#place:hover > #line5 {
    height: 0.25rem;
    transition: 0.25s;
}

#contact:hover > #line6 {
    height: 0.25rem;
    transition: 0.25s;
}

#abstracts:hover > #line7 {
    height: 0.25rem;
    transition: 0.25s;
}

.giswnauce__navbar-menubtn {
    align-items: center;
    justify-content: center;
    display: none;
}

.giswnauce__navbar-menubtn_button {
    background: none;
    border: 0;
    cursor: pointer;
}

.burger, .burger::before, .burger::after {
    background: var(--color-navy);
}

.giswnauce__navbar-menu_cont {
    display: none;
    width: 100%;
    z-index: 2;
}

.giswnauce__navbar-menu {
    right: 0rem;
    height: fit-content;
    width: 100%;
    background: var(--color-white);
    border-bottom: 0.25rem solid var(--color-navy);
    box-shadow: 0px 1rem 1rem -1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0rem;
    z-index: 2;
}

.giswnauce__navbar-menu p {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-navy);
    width: fit-content;
}

.giswnauce__navbar-menu p:hover {
    text-decoration: underline;
    transition: 0.25s;
}

.giswnauce__navbar-committees-dropdown {
    width: 100%;
    z-index: 2;
}

.committees-dropdown-menu {
    margin-left: 6rem;
    height: fit-content;
    width: 15rem;
    background: var(--color-turquoise);
    border-left: 0.25rem solid var(--color-navy);
    border-bottom: 0.25rem solid var(--color-navy);
    border-right: 0.25rem solid var(--color-navy);
    box-shadow: 0px 1rem 1rem -1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0rem;
    z-index: 2;
}

.committees-dropdown-menu p {
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--color-navy);
    width: fit-content;
    cursor: pointer;
}

.committees-dropdown-menu p:hover {
    text-decoration: underline;
    transition: 0.25s;
}

.scroll-committees-dropdown-fixed {
    position: fixed;
    top: 3rem;
}

.scroll-committees-dropdown-absolute {
    position: absolute;
}

@media screen and (max-width: 1200px) {
.scroll-nav {
    width: 898px;
}
.giswnauce__navbar-links p {
    font-size: 1.2rem;
}
.committees-dropdown-menu {
    margin-left: 5rem;
}
.committees-dropdown-menu p {
    font-size: 1.2rem;
}
}

@media screen and (max-width: 1024px) {
.scroll-nav {
    width: 798px;
}
.giswnauce__navbar {
    padding: 0 2rem;
}
.giswnauce__navbar-links {
    gap: 1.5rem;
}
.giswnauce__navbar-links p {
    font-size: 1.15rem;
}
.committees-dropdown-menu {
    margin-left: 4.5rem;
}
.committees-dropdown-menu p {
    font-size: 1.15rem;
}
}

@media screen and (max-width: 900px) {
.scroll-nav {
    width: 648px;
}
.giswnauce__navbar-links p {
    font-size: 1.05rem;
}
.giswnauce__navbar-links {
    gap: 1rem;
}
.committees-dropdown-menu {
    margin-left: 4rem;
    width: 13rem;
}
.committees-dropdown-menu p {
    font-size: 1.05rem;
}
}

@media screen and (max-width: 768px) {
.giswnauce__navbar {
    height: 2.5rem;
}
.scroll-nav {
    width: 498px;
}
.giswnauce__navbar {
    padding: 0 1.5rem;
}
.giswnauce__navbar-links {
    gap: 0.5rem;
}
.giswnauce__navbar-links p {
    font-size: 0.9rem;
}
.committees-dropdown-menu {
    margin-left: 3.5rem;
    width: 12rem;
}
.committees-dropdown-menu p {
    font-size: 0.9rem;
}
.scroll-committees-dropdown-fixed {
    top: 2.5rem;
}
}

@media screen and (max-width: 600px) {
.giswnauce__navbar-menu_cont {
    display: flex;
}
.giswnauce__navbar-committees-dropdown {
    display: none;
}
.giswnauce__navbar-links {
    display: none;
}
.giswnauce__navbar {
    justify-content: flex-end;
}
.giswnauce__navbar-menubtn {
    display: flex;
}
.scroll-nav {
    width: 100%;
}
}