* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    user-select: none;
    width: 1050px;
    margin: 0 auto;
    margin-top: 3rem;
    border: 1px solid black;
    box-shadow: 0px 0px 10px 5px var(--color-black);
    background-image: url('assets/Site_Cover.svg');
    background-size: 100%;
    background-attachment: repeat-y;
}

.App {
    position: relative;
    padding-bottom: 2rem;
}

a {
    color: unset;
    text-decoration: none;
}

.giswnauce__col {
    display: flex;

}

.giswnauce__col-left {
    width: 100%;
}

.giswnauce__col-right {
    width: 15rem;
    min-height: 100%;
    background-color: var(--color-white);
}

.scroll-rightmargin {
    margin-top: 3rem;
}

.anchor-about {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }

@media screen and (max-width: 1200px) {
body {
    width: 900px;
}
.giswnauce__col-right {
    width: 14rem;
}
}

@media screen and (max-width: 1024px) {
body {
    width: 800px;
}
.giswnauce__col-right {
    width: 13rem;
}
}

@media screen and (max-width: 900px) {
body {
    width: 650px;
}
.giswnauce__col-right {
    width: 12rem;
}
}

@media screen and (max-width: 768px) {
.scroll-rightmargin {
    margin-top: 2.5rem;
}
body {
    width: 500px;
}
.giswnauce__col-right {
    width: 10rem;
}
}

@media screen and (max-width: 600px) {
body {
    width: 100%;
    border: 0;
    border-top: 1px solid black;
    margin-top: 0rem;
}
.giswnauce__col-right {
    background-color: transparent;
    width: 9rem;
}
}