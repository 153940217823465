.scroll-margin {
    margin-top: 3rem;
}

.giswnauce__main {
    position: relative;
    padding: 3rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--color-white);
}

.giswnauce__main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.content-description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}

.content-description h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: var(--color-navy);
    margin-bottom: 1.5rem;
}

.content-description h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.75rem;
    color: var(--color-black);
    margin-bottom: 1.5rem;
}

.content-description p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--color-black);
    margin-bottom: 1rem;
    text-align: justify;
}

.content-description h3 {
    margin-top: 2rem;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0.5rem 1.5rem;
    border: 0.25rem solid var(--color-navy);
    border-radius: 1rem;
    background-color: var(--color-turquoise);
    color: var(--color-navy);
    transition: 0.25s;
}

.content-description a:hover > h3 {
    background-color: var(--color-navy);
    color: var(--color-turquoise);
    transition: 0.25s;
}

@media screen and (max-width: 1200px) {
.content-description h1 {
    font-size: 2.2rem;
    line-height: 2.2rem;
}
.content-description h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.content-description p {
    font-size: 1.15rem;
}
.content-description h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
}
}
    
@media screen and (max-width: 1024px) {
.giswnauce__main {
    padding: 2rem 2rem;
}
.content-description h1 {
    font-size: 2rem;
    line-height: 2rem;
}
.content-description h2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
}
.content-description p {
    font-size: 1.1rem;
}
.content-description h3 {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.65rem;
}
}
    
@media screen and (max-width: 900px) {
.content-description h1 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
}
.content-description h2 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
}
.content-description p {
    font-size: 1rem;
    margin-bottom: 0.75rem;
}
.content-description h3 {
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
}
}
    
@media screen and (max-width: 768px) {
.giswnauce__main {
    padding: 1.5rem 1.5rem;
}
.scroll-margin {
    margin-top: 2.5rem;
}
.content-description h1 {
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-bottom: 0.75rem;
}
.content-description h2 {
    font-size: 1.15rem;
    line-height: 1.15rem;
    margin-bottom: 0.75rem;
}
.content-description p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}
.content-description h3 {
    margin-top: 1rem;
    font-size: 1.15rem;
    line-height: 1.4rem;
}
}
    
@media screen and (max-width: 600px) {
.content-description h1 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
}
.content-description h2 {
    font-size: 1.05rem;
    line-height: 1.05rem;
    margin-bottom: 0.5rem;
}
.content-description p {
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}
.content-description h3 {
    margin-top: 0.75rem;
    font-size: 1.05rem;
    line-height: 1.25rem;
}
.giswnauce__main {
    background-color: transparent;
}
}